@import "~assets/scss/variables";

.modal {
	max-width: none;
	background: url("~assets/img/pop-up_notif.png") center/contain no-repeat;
	width: 75vmin;
	height: 45vmin;
	box-shadow: none;
	text-align: center;
	position: relative;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		width: 93vmin;
		height: 55vmin;
	}
}

.closeBtn {
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		width: 5vw;
		top: 1vw;
		right: 1vw;
	}
}

.content {
	font-size: 2.5vmin;
	position: relative;
	top: 61%;
	transform: translateY(-61%);

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		font-size: 4vmin;
	}
}

.button {
	position: absolute;
	bottom: 5%;
	height: 8%;
	width: 90%;
	margin: auto;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		height: 11%;
	}

	.panduan {
		width: 32%;
		background: url("~assets/img/button_panduan.png") center/contain no-repeat;
		cursor: pointer;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: 40%;
		}
	}

	.action {
		width: 37%;
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			width: 50%;
		}

		> div {
			width: 49%;
			cursor: pointer;
			&:first-child {
				background: url("~assets/img/button_blokir.png") center/contain no-repeat;
			}
			&:last-child {
				background: url("~assets/img/button_aktifkan.png") center/contain no-repeat;
			}
		}
	}
}
