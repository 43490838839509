@import '~assets/scss/variables';

.bannerParent {
    position: relative;
    background: $red;
    height: 36vh;
    background-position: center;
    background-size: cover;
    transition: background-image 0.2s ease-in-out;
    background-repeat: no-repeat;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        height: 33vw;
    }
}