@import "~assets/scss/variables";

.modalCont {
	padding: 1vw 3vw;
	text-align: center;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		padding: 4vw 8vw;
	}

	.title {
		text-align: center;
		font-family: "ExtraBold";
		margin-bottom: 1.5vw;
		font-size: 1.5vw;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			font-size: 3vw;
		}
	}

	> div {
		display: flex;
		justify-content: center;
		gap: 3vmin;
		flex-wrap: wrap;

		a {
			display: inline-block;
			text-align: center;
			color: #fff !important;
			text-decoration: none !important;
			outline: none;
			width: 7vh;
			// margin-right: 1vw;

			// @media screen and (max-width: $mobile) and (orientation: portrait) {
			// 	// margin-right: 2vw;
			// 	width: 15vw;
			// }

			img {
				width: 100%;
			}

			&:last-child {
				margin: 0;
			}
		}
	}
}
