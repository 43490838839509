@import '~assets/scss/variables';

.navbar {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        margin-top: 12vw;
    }
}

.desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 1% 10%;
    font-size: 1vw;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        display: none;
    }

    &__left {
        color: $red;

        > a img {
            width: 60%;
        }
    }

    &__right {
        display: flex;
        > a, span {
            display: block;
            padding: 0 1vw;
            border-right: 1px solid $red;
            text-decoration: none !important;
            color: $red !important;
            cursor: pointer;

            &:last-child {
                margin: 0;
                border: 0;
            }
        }
    }
}

.mobile {
    display: none;
    text-align: center;
    padding: 3vw 0;
    position: fixed;
    top: 0;
    z-index: 999099999;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid $red;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        display: block;
    }

    > a img {
        width: 40%;
    }
}

.hamburger {
	position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(.7);
}

.hamburger_box {
	top: 2px;
	width: 8vw !important;
}

.overlay_menu{
	position: absolute;
	width: 100%;
    height: 100vh;
    z-index: 99;
    top: 12vw;
    visibility: hidden;
}

.overlay_menu_active{
    background: rgba(0, 0, 0, 0.55);
    visibility: initial;
}

.nav_menu {
	position: fixed;
	left: -70%;
	width: 70%;
	background: #fff;
	padding: 8% 8% 0;
	transition: all .2s ease-in-out;
	color: #fff !important;
	height: 100vh;
	overflow-y: auto;
    z-index: 99;
    
    ul {
        list-style: none;
        padding: 0;
        text-align: left;

        li {
            border-bottom: 1px solid $grey2;
            
            a, span {
                padding: 5vw 0;
                display: block;
                width: 100%;
                text-decoration: none;
                font-family: "SemiBold";
                font-size: 5vw;
                color: $red;
            }
        }
    }
}

.nav_menu_active {
	left: 0;
}

:global {
    .hamburger {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%) scale(.7);
    }

    .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
        background-color: $red;
    }
}