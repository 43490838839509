@import '~assets/scss/variables';

.listTicket {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        margin: 5vw auto;
    }
}

.noData {
    font-size: 1vw;
    text-align: center;
    font-display: SemiBold;
    margin-top: 2vw;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 4vw;
    }
}