@import '~assets/scss/variables';

.form {
  @media screen and (max-width: $mobile) and (orientation: portrait) {
    margin: 6vw auto;
  }

  .group {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
      margin-bottom: 5vw;
    }

    label {
      @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 4vw;
        font-family: 'SemiBold';
        line-height: 1.25;
        margin-bottom: 2vw;
      }
    }

    input,
    select,
    textarea {
      @media screen and (max-width: $mobile) and (orientation: portrait) {
        font-size: 3vw;
        height: initial;
        padding: 3vw 3vw;
      }
    }

    input[type='file'] {
      color: rgba(0, 0, 0, 0);
    }

    &.textarea {
      position: relative;

      .textLimit {
        position: absolute;
        z-index: 1;
        right: 1%;
        bottom: -11%;
        font-size: 0.8vw;
        color: rgba(33, 37, 41, 0.796);

        @media screen and (max-width: $mobile) and (orientation: portrait) {
          font-size: 2.5vw;
          right: 1%;
        }
      }
    }
  }

  .submit {
    margin-top: 1vh;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
      font-size: 3vw;
      padding: 2vw 8vw;
    }
  }
}

.subHeader {
  font-size: 0.7vw;
  font-style: italic;
  margin-bottom: 0.6vw;

  @media screen and (max-width: $mobile) and (orientation: portrait) {
    font-size: 2.5vw;
    line-height: 1.5;
  }
}

.error {
  font-size: 0.7vw;
  font-style: italic;
  color: #ff0000;
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
  text-transform: lowercase;

  @media screen and (max-width: $mobile) and (orientation: portrait) {
    font-size: 2.5vw;
    line-height: 1.5;
  }
}

.helpText {
  font-size: 0.7vw;
  font-style: italic;
  margin-top: 0.3vw;

  @media screen and (max-width: $mobile) and (orientation: portrait) {
    font-size: 2.5vw;
    line-height: 1.5;
  }
}

.uploadList {
  max-width: 9vw;
  font-size: 0.8vw;
  margin-bottom: 0.5vw !important;

  @media screen and (max-width: $mobile) and (orientation: portrait) {
    max-width: 35vw;
    font-size: 3vw;
    padding: 1.5vw 2vw;
    margin-bottom: 3vw !important;
    margin-right: 1vw !important;
  }
}

.uploadListCont {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 1vw;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dc3545;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  &::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  &::-webkit-scrollbar-track:active {
    background: #333333;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  .loader {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}
