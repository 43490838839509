@import '~assets/scss/variables';

.modal {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        min-width: 90%;
        padding: 5vw;
    }
}

.closeBtn {
    @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 5vw;
        top: 1vw;
        right: 1vw;
    }
}

.modalCont {
    padding: 1vw 3vw;
    width: 25vw;
    margin: auto;

    @media screen and (max-width: $mobile) and (orientation: portrait) {
        width: 100%;
    }

    .title {
        font-family: "SemiBold";
        font-size: 150%;
        margin-bottom: 1.5vw;
        text-align: center;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 5vw;
        }
    }

    .content {
        text-align: center;

        @media screen and (max-width: $mobile) and (orientation: portrait) {
            font-size: 3vw;
            line-height: 1.5;

            * {
                font-size: 3vw;
                line-height: 1.5;
            }
        }
        
        ul {
            padding: 0;
            list-style: none;
        }
    }
}